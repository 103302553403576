import React from 'react';

import './footer.scss';

const Footer = () => {
  return (
    <footer>
      <p>Copyright © David Vodrážka 2020</p>
    </footer>
  );
}

export default Footer;
